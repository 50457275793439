import { ButtonGroup, Container, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../slices/authSlice"
import CircularProgress from '@mui/material/CircularProgress';
import { RootState } from "../store"
import { approveDietician, denyDietician, addPatientGroup, deletePatientGroup, updateAdminPage, grantTempAccess } from '../apiCalls'

/* 
    Page for the institution admin to manage the institution
    Accept/Deny Deititians
    Add/Remove Patient Groups
    See Patients in institution
*/
function AdminPage() {
    const dispatch = useDispatch()

    const [update, setUpdate] = useState(false)

    const isLoading = useSelector((state: RootState) => state.adminPage.isLoading)

    const ins_name = useSelector((state: RootState) => state.auth.admin_ins_name)
    const patientNames = useSelector((state: RootState) => state.adminPage.patients)
    const patientGroups = useSelector((state: RootState) => state.adminPage.patientGroups)
    const dieticians = useSelector((state: RootState) => state.adminPage.dieticians)

    const [newGroupName, setNewGroupName] = useState<string>('')

    useEffect(() => {
        dispatch(updateAdminPage())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    const addNewPatientGroup = () => {
        dispatch(addPatientGroup(newGroupName))
        setUpdate(!update)
    }

    const deletePatientGroupCall = (group_id: string) => {
        dispatch(deletePatientGroup(group_id))
        setUpdate(!update)
    }

    const acceptOrDenyDietician = (accept: boolean, dietician_id: string) => {
        if (accept) {
            dispatch(approveDietician(dietician_id))
        } else {
            dispatch(denyDietician(dietician_id))
        }
        setUpdate(!update)
    }

    const grantTempDieticianAccess = (dietician_id: string) => {
        dispatch(grantTempAccess(dietician_id))
        setUpdate(!update)
    }

    return (
        <div style={{ minHeight: '100vh' }}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" component="div">
                        Sciencella Admin Dashboard
                    </Typography>
                    <Box style={{ flex: 1 }} />
                    <Button color="inherit" onClick={() => dispatch(logout())}>Logout</Button>
                </Toolbar>
            </AppBar>
            <Box>
                <Container>
                    {
                        (ins_name && !isLoading) ?
                            <div>
                                <h1>{ins_name}</h1>
                                <Divider />
                                <h4>Dietician Status</h4>
                                <Box>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Last Name:</TableCell>
                                                    <TableCell>First Name:</TableCell>
                                                    <TableCell>Email:</TableCell>
                                                    <TableCell>Actions:</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dieticians.map((dietician, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{dietician.last_name}</TableCell>
                                                            <TableCell>{dietician.first_name}</TableCell>
                                                            <TableCell>{dietician.email}</TableCell>
                                                            <TableCell align="right">
                                                                <ButtonGroup variant="contained" size="small">
                                                                    <Button
                                                                        color="success"
                                                                        disabled={dietician.approved}
                                                                        onClick={() => acceptOrDenyDietician(true, dietician.id)}
                                                                    >
                                                                        Accept
                                                                    </Button>
                                                                    <Button
                                                                        color="error"
                                                                        onClick={() => acceptOrDenyDietician(false, dietician.id)}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                    <Button
                                                                        color="info"
                                                                        onClick={() => grantTempDieticianAccess(dietician.id)}
                                                                    >
                                                                        Grant 24H Access
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Divider />
                                <h4>Patient Groups</h4>
                                <Box>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Group Name:</TableCell>
                                                    <TableCell>Actions:</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {patientGroups.map((G, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{G.name}</TableCell>
                                                            <TableCell align="right">
                                                                <Button variant="contained" size="small" color="error" onClick={() => deletePatientGroupCall(G.id)}>Delete Group</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                <TableRow>
                                                    <TableCell>Add Group:</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            id="new_ins_name"
                                                            label="New Patient Group Name"
                                                            name="new_ins_name"
                                                            autoFocus
                                                            size='small'
                                                            variant="standard"
                                                            onChange={(event) => setNewGroupName(event.target.value)}
                                                            InputProps={{ endAdornment: <Button color='success' onClick={() => addNewPatientGroup()}>Add</Button> }}
                                                        />

                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Divider />
                                <h4>Patients</h4>
                                <Box>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Last Name:</TableCell>
                                                    <TableCell>First Name:</TableCell>
                                                    <TableCell>Email:</TableCell>
                                                    <TableCell>Gender:</TableCell>
                                                    <TableCell>Birthdate:</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {patientNames.map((P, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{P.last_name}</TableCell>
                                                            <TableCell>{P.first_name}</TableCell>
                                                            <TableCell>{P.email}</TableCell>
                                                            <TableCell>{P.gender ? P.gender : 'N/A'}</TableCell>
                                                            <TableCell>{P.birth_date ? P.birth_date.toLocaleDateString() : 'N/A'}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </div>
                            :
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{ minHeight: '80vh' }}
                            >

                                <Grid item xs={3}>
                                    <CircularProgress />
                                </Grid>

                            </Grid>
                    }
                </Container>
            </Box>
        </div>
    )
}

export default AdminPage