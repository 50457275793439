import { PatientSimple } from "../../slices/patientDataSlice";
import Modal from "@mui/material/Modal";
import TextField from '@mui/material/TextField';
import { Button, Container, CardContent, CardHeader, IconButton, Autocomplete, ButtonGroup, MenuItem, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Icon from '@mui/material/Icon';
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close"
import { axiosInstance, sendNotification } from "../../apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ArrowBack } from "@mui/icons-material";
import recs from '../../templates'

interface NotificationPatientInfo {
    uuid: string,
    first_name: string
    last_name: string
    email: string
    ins_id: string
    ins_name: string
}

/* 
    Modal that lets dietician either select patient to message or if patient is
    passed in as a prop uses that patient. Then allows dietician to create and
    send message
*/
function ModalReply(props: { currentP: PatientSimple | null, openModal: boolean, onClose: () => void, selectPatient: (patient: PatientSimple | null) => void, patientLocked: boolean }) {
    const dispatch = useDispatch()

    const institutions = useSelector((state: RootState) => state.patientData.institutions)

    const [template, setTemplate] = useState('')

    const [notification_type, setNotifType] = useState('')
    const [text, setText] = useState('')
    const [patientsInfo, setPatientsInfo] = useState<NotificationPatientInfo[]>([])

    useEffect(() => {
        const getPatientInfo = async () => {
            const patientIDs = await axiosInstance.get('user/patients')
                .then((res) => {
                    return res.data.patients.map((x: any) => {
                        return x.patient_id
                    })
                })

            const patientInfo = await axiosInstance.post('patientuser/batch', {
                patients: patientIDs.map((x: string) => {
                    return {
                        patient_id: x
                    }
                }),
                search_term: ['first_name', 'last_name', 'email', 'uuid', 'ins_id']
            })
                .then((res) => {
                    return res.data.patients.map((P: any) => {
                        return {
                            ...P,
                            ins_name: institutions.find((I) => I.id === P.ins_id)?.name
                        }
                    })
                })

            setPatientsInfo(patientInfo)
        }

        if (!props.currentP) {
            getPatientInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setText(template)
    }, [template])

    const sendValue = () => {
        if (props.currentP) {
            dispatch(sendNotification({ patient_id: props.currentP.id, notification_type, text }))
        }
        props.onClose()
    }

    const getInputFromTextBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    }

    return (
        <Modal
            open={props.openModal}
            onClose={props.onClose}
            sx={{ overflow: 'scroll' }}>
            <Container maxWidth="xl" sx={{ padding: '3rem' }} >
                {
                    props.currentP ?
                        <Card>
                            <CardHeader title={"Reply to " + props.currentP.first_name.concat(' ', props.currentP.last_name)} action={
                                <IconButton onClick={props.onClose}>
                                    <CloseIcon />
                                </IconButton>
                            } />
                            <CardContent>
                                <TextField
                                    label='Notification Subject'
                                    variant="filled"
                                    onChange={(e) => setNotifType(e.target.value)}
                                    fullWidth
                                />
                                <TextField
                                    id='outlined-textarea'
                                    label='Message'
                                    placeholder={"Reply to " + props.currentP.first_name.concat(' ', props.currentP.last_name)}
                                    multiline
                                    variant="filled"
                                    rows={10}
                                    onChange={getInputFromTextBox}
                                    fullWidth
                                    value={text}
                                />
                                <Divider sx={{ mb: 2, mt: 2 }} />
                                <TextField
                                    select
                                    fullWidth
                                    label='Symptom Response Template'
                                    value={template}
                                    onChange={(e) => setTemplate(e.target.value)}
                                >
                                    <MenuItem value=''>None</MenuItem>
                                    {
                                        recs.map((R, i) => {
                                            const name = (props.currentP ? props.currentP.first_name.concat(' ', props.currentP.last_name) : '')
                                            return (
                                                <MenuItem key={i} value={R[1](name)}>{R[0]}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                                <Divider sx={{ mb: 2, mt: 2 }} />
                                <ButtonGroup fullWidth variant="contained">
                                    {
                                        props.patientLocked ?
                                            null
                                            :
                                            <Button
                                                startIcon={<ArrowBack />}
                                                onClick={() => props.selectPatient(null)}
                                            >
                                                Back
                                            </Button>
                                    }
                                    <Button
                                        endIcon={<Icon>send</Icon>}
                                        onClick={sendValue}
                                    >
                                        Send
                                    </Button>
                                </ButtonGroup>
                            </CardContent>
                        </Card>
                        :
                        <Card>
                            <CardHeader title="Select Patient to Message" action={
                                <IconButton onClick={props.onClose}>
                                    <CloseIcon />
                                </IconButton>
                            } />
                            <CardContent>
                                <Autocomplete
                                    disablePortal
                                    options={patientsInfo}
                                    groupBy={(option) => option.ins_name}
                                    getOptionLabel={(option) => option.first_name.concat(' ', option.last_name)}
                                    renderInput={(params) => <TextField {...params} label="Select Patient" />}
                                    sx={{ width: '100%' }}
                                    onChange={(_, v) => {
                                        if (props.selectPatient) {
                                            if (v) {
                                                props.selectPatient({
                                                    id: v.uuid,
                                                    email: v.email,
                                                    first_name: v.first_name,
                                                    last_name: v.last_name,
                                                    ins_id: v.ins_id,
                                                    gender: '',
                                                    height: '',
                                                    birth_date: '123'
                                                })
                                            } else {
                                                props.selectPatient(null)
                                            }
                                        }
                                    }}
                                />
                            </CardContent>
                        </Card>
                }
            </Container>
        </Modal>
    )
}


export default ModalReply;
