import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { useEffect, useState } from 'react';
import { InsInfoType } from '../slices/institutionSlice'
import { registerUser } from '../apiCalls'
import { useDispatch, useSelector } from 'react-redux';
import { clearError, setError } from '../slices/errorSlice';
import { isRegisterFormDataValid } from '../util';
import { Autocomplete, Divider } from '@mui/material';
import { getInstitutions } from '../apiCalls'
import { RootState } from '../store';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Sciencella.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

/* 
    Page for the dietitian to register their account
    and input the institutions for which they want access to
*/
export default function SignUpPage() {
  const dispatch = useDispatch()

  const isLoading = useSelector((state: RootState) => state.institutions.isLoading)
  const institutions = useSelector((state: RootState) => state.institutions.institutions)
  const [selectedIns, setSelectedIns] = useState<InsInfoType[]>([])

  useEffect(() => {
    dispatch(getInstitutions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Function that checks if input is valid and sends API requests
  const register = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget)
    const result = isRegisterFormDataValid(data, selectedIns);

    if (selectedIns) {
      if (result !== true) {
        dispatch(setError(result));
        return;
      }

      const username = String(data.get('username'))
      const email = String(data.get('email')).toLowerCase()
      const first_name = String(data.get('first_name'))
      const last_name = String(data.get('last_name'))
      const password = String(data.get('password'))

      dispatch(clearError())

      dispatch(
        registerUser(
          {
            username,
            email,
            password,
            first_name,
            last_name,
            institutions: selectedIns
          }
        )
      )
    } else {
      dispatch(setError('Must select an organization'))
    }
  };

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: InsInfoType[]) => {
    if (value) {
      setSelectedIns(value)
    } else {
      setSelectedIns([])
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Icon sx={{ color: "#ffffff" }}>add</Icon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box name="form" component="form" onSubmit={register} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="first_name"
              label="First Name"
              type="first_name"
              id="first_name"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="last_name"
              label="Last Name"
              type="last_name"
              id="last_name"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
            />

            <Divider />
            <Autocomplete
              multiple
              disabled={isLoading}
              disablePortal
              id="ins_id"
              options={institutions}
              sx={{ width: "100%", mt: 2 }}
              renderInput={(params) => <TextField {...params} label="Organization *" />}
              onChange={handleChange}
              getOptionLabel={(option) => {
                return option.name
              }}
            />
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Create An Account
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Have an account with us? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider >
  );
}
