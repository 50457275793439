const appetite_decrease = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had a lack of appetite.  Due to this, it is recommended that you eat smaller meals and make sure to eat high protein/calorie foods and exercise when possible.`
}

const early_satiety = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had early satiety.  Due to this, it is recommended that you eat foods low in fat. Also try to eat smaller meals more often throughout the day.`
}

const taste_changes = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had a change in your taste. If you lost some of your sense of taste, it is recommended you try some foods with strong flavors. If foods taste like metal or bitter, try room temperature or cold foods`
}

const nausea = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had nausea/vomiting.  Due to this, it is recommended that you eat smaller and more meals with foods that have bland taste, eat in cooler rooms with food that is cold or at room temperature and make sure to avoid foods with strong smells.`
}

const vomiting = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had nausea/vomiting.  Due to this, it is recommended that you eat smaller and more meals with foods that have bland taste, eat in cooler rooms with food that is cold or at room temperature and make sure to avoid foods with strong smells.`
}

const diarrhea = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had diarrhea.  Due to this, it is recommended that you make sure to drink a lot of clear liquids and lie down at least 30 minutes after finishing meals, avoid greasy, fried, spicy sweet foods and take medications and supplements that help with diarrhea.`
}

const constipation = (pName: string) => {
    return `Dear ${pName},  In your report on your diet within the last week, I saw that you had constipation.  Due to this, it is recommended that you eat meals at the same time every single day, drink a lot of water and increase your fiber intakes (unless you are on a fiber-restricted diet), have a hot beverage with meals to stimulate your bowel movements, increase your physical activities and  have laxatives after meals.`
}

const symptom_recommendations: [string, ((pName: string) => string)][] = [
    ['Appetite Decrease', appetite_decrease],
    ['Early Satiety', early_satiety],
    ['Taste Change', taste_changes],
    ['Nausea', nausea],
    ['Vomiting', vomiting],
    ['Diarrhea', diarrhea],
    ['Constipation', constipation]
]

export default symptom_recommendations