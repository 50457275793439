import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

import { loginUser, registerUser, logoutUser, adminLogin, refreshAccessToken } from '../apiCalls'
import { InsInfoType } from './institutionSlice'

interface LoginReturnObject {
    authToken: string,
    refreshToken: string
    remember: FormDataEntryValue | null
}

interface AdminLoginReturnObject {
    authToken: string,
    refreshToken: string
    ins_id: string
    ins_name: string
}

interface RegisterReturnObject {
    authInfo: {
        authToken: string,
        refreshToken: string
    },
    ins: InsInfoType[]
}

interface AuthState {
    authToken: string | null,
    refreshToken: string | null,
    isLoading: boolean
    isAdmin: boolean
    ins: InsInfoType[]
    admin_ins_id: string,
    admin_ins_name: string
}

const initialState: AuthState = {
    authToken: (localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null),
    refreshToken: (localStorage.getItem('refreshToken') ? localStorage.getItem('authToken') : null),
    isLoading: false,
    isAdmin: false,
    ins: [],
    admin_ins_id: '',
    admin_ins_name: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
        },
        setAdminAccessToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
            state.isAdmin = true
        },
        logout: (state) => {
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')

            state.authToken = ''
            state.refreshToken = ''
            state.isAdmin = false
            state.isLoading = false
        }
    },
    extraReducers: {
        // Here is where asynchronous logic should be handled with thunk functions located in the apiCalls file
        [loginUser.pending.type]: (state) => {
            state.isLoading = true
        },
        [loginUser.fulfilled.type]: (state, action: PayloadAction<LoginReturnObject>) => {
            const authInfo = action.payload

            state.authToken = authInfo.authToken
            state.refreshToken = authInfo.refreshToken

            if (authInfo.remember) {
                localStorage.setItem('authToken', authInfo.authToken)
                localStorage.setItem('refreshToken', authInfo.refreshToken)
            }

            state.isLoading = false
        },
        [loginUser.rejected.type]: (state) => {
            state.isLoading = false
        },
        [registerUser.pending.type]: (state) => {
            state.isLoading = true
        },
        [registerUser.fulfilled.type]: (state, action: PayloadAction<RegisterReturnObject>) => {
            const authInfo = action.payload.authInfo

            state.authToken = authInfo.authToken
            state.refreshToken = authInfo.refreshToken

            state.ins = action.payload.ins

            state.isLoading = false
        },
        [registerUser.rejected.type]: (state) => {
            state.isLoading = false
        },
        [logoutUser.pending.type]: (state) => {
            state.isLoading = true
        },
        [logoutUser.fulfilled.type]: (state) => {
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')

            state.authToken = ''
            state.refreshToken = ''
            state.isAdmin = false
            state.isLoading = false
        },
        [logoutUser.rejected.type]: (state) => {
            localStorage.removeItem('authToken')
            localStorage.removeItem('refreshToken')

            state.authToken = ''
            state.refreshToken = ''
            state.isAdmin = false
            state.isLoading = false
        },
        [adminLogin.pending.type]: (state) => {
            state.isLoading = true
        },
        [adminLogin.fulfilled.type]: (state, action: PayloadAction<AdminLoginReturnObject>) => {
            const authInfo = action.payload

            state.isAdmin = true
            state.authToken = authInfo.authToken
            state.refreshToken = authInfo.refreshToken
            state.admin_ins_id = authInfo.ins_id
            state.admin_ins_name = authInfo.ins_name

            state.isLoading = false
        },
        [adminLogin.rejected.type]: (state) => {
            state.isLoading = false
        },
        [refreshAccessToken.fulfilled.type]: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
        }
    }
})

export const { setAccessToken, setAdminAccessToken, logout } = authSlice.actions

// Selectors for accessing that part of the state using useSelector within components
export const selectAuthToken = (state: RootState) => state.auth.authToken
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken
export const isAuthLoading = (state: RootState) => state.auth.isLoading
export const isAuthAdmin = (state: RootState) => state.auth.isAdmin

export default authSlice.reducer