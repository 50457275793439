import { Autocomplete, Box, Button, ButtonGroup, CardContent, CardHeader, CircularProgress, Container, Divider, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import { Chart } from "react-google-charts";
import { Group, Patient, PatientSimple } from "../../slices/patientDataSlice";
import CloseIcon from "@mui/icons-material/Close"
import { useEffect, useState } from "react";
import DailyNutrientTable from "./DailyNutrientTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import ModalReply from "./NotificationReply"
import { addPatientToGroup, axiosInstance, deleteNotification, deletePatientFromGroup, setPatientInfo } from "../../apiCalls";
import { Notification } from '../../slices/patientDataSlice'

const macroOptions = [
    [1, 'Calcium'],
    [2, 'Carbohydrate'],
    [3, 'Cholesterol'],
    [4, 'Energy'],
    [5, 'Fiber'],
    [6, 'Iron'],
    [7, 'Monounsaturated Fatty Acid'],
    [8, 'Polyunsaturated Fatty Acid'],
    [9, 'Protein'],
    [10, 'Saturated Fatty Acid'],
    [11, 'Sodium'],
    [12, 'Sugar'],
    [13, 'Total Lipid'],
    [14, 'Vitamin A'],
    [15, 'Vitamin C'],
    [16, 'Vitamin D']
]

/* 
    Modal that displays specific information on a single patient.

    Loaded on component mount:
    Specific patient info
    Patient Groups
    Scores chart
*/
function ModalPatientProfile(props: { openModal: boolean, onClose: () => void }) {
    const dispatch = useDispatch()

    const [refreshNeeded, setRefreshNeeded] = useState(false)
    const patient = useSelector((state: RootState) => state.patientData.selectedPatient)

    const [groups, setGroups] = useState<Group[]>()
    const [notifications, setNotifications] = useState<Notification[]>([])
    const allNotifications = useSelector((state: RootState) => state.patientData.notifications)

    const [chartShown, setChartShown] = useState<string>()
    const [days, setDays] = useState<number | null>(30)
    const [chartData, setChartData] = useState<string[][]>([])
    const [chartLoading, setChartLoading] = useState(false)
    const [selectedMacros, setSelectedMacros] = useState<any[][]>([])
    const [warningMeals, setWarningMeals] = useState<any[]>([])

    const [selectedReplyPatient, setSelectedReplyPatient] = useState<PatientSimple | null>(null)
    const [replyModalOpen, setReplyModalOpen] = useState(false)

    // Setting default values on mount
    useEffect(() => {
        setChartShown('scores')
        setDays(30)
        setChartData([])
        setChartLoading(false)
        setSelectedMacros([])

        const getScores = async () => {
            setChartLoading(true)
            await axiosInstance.get('daily_score', {
                params: {
                    patient_id: patient?.uuid,
                    days
                }
            })
                .then(res => {
                    const scoreChart = [['Date', 'Score']].concat(
                        res.data.scores.map((S: any) => {
                            return [S.date, S.score]
                        }).sort((a: any, b: any) => {
                            return a[0].localeCompare(b[0])
                        })
                    )

                    if (scoreChart.length > 0) {
                        setChartData(scoreChart)
                        setChartLoading(false)
                    }
                })
        }

        if (patient) {
            getScores()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Loading all of the values intitially
    useEffect(() => {
        const getGroups = async () => {
            const groupIDS = await axiosInstance.get('institution/groups', { params: { ins_id: patient?.ins_id } })
                .then(res => {
                    return res.data.groups.map((X: any) => {
                        return X.group_id
                    })
                })

            const userGroups = await axiosInstance.get('patient_patient_group/patient', { params: { patient_id: patient?.uuid } })
                .then(res => {
                    return res.data.group.map((X: any) => {
                        return X.group_id
                    })
                })

            const requests = groupIDS.map((id: string) => {
                return axiosInstance({
                    method: 'GET',
                    url: 'patient_group/retrieve',
                    params: {
                        group_id: id
                    }
                })
            })

            await Promise.all(requests)
                .then(responses => {
                    const groups = responses.map(res => {
                        const access = userGroups.find((X: string) => X === res.data.group_id) ? true : false

                        return {
                            id: res.data.group_id,
                            name: res.data.group_name,
                            subscribed: access,
                            loading: false
                        }
                    })

                    setGroups(groups)
                })
        }

        const grabNotifications = () => {
            const temp = allNotifications.slice().filter((N) => {
                return ((N.patient_id === patient?.uuid) && !N.to_patient)
            }).sort((a, b) => {
                return b.timestamp.localeCompare(a.timestamp)
            })

            setNotifications(temp)
        }

        const getScores = async () => {
            setChartLoading(true)
            await axiosInstance.get('daily_score', {
                params: {
                    patient_id: patient?.uuid,
                    days
                }
            })
                .then(res => {
                    const scoreChart = [['Date', 'Score']].concat(
                        res.data.scores.map((S: any) => {
                            return [S.date, S.score]
                        }).sort((a: any, b: any) => {
                            return a[0].localeCompare(b[0])
                        })
                    )

                    if (scoreChart.length > 0) {
                        setChartData(scoreChart)
                        setChartLoading(false)
                    }
                })
        }

        if (patient || refreshNeeded) {
            getGroups()
            grabNotifications()
            setChartShown('scores')
            getScores()
            setRefreshNeeded(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient, refreshNeeded, allNotifications])

    // Updating the chart shown based on state
    useEffect(() => {
        const getScores = async () => {
            setChartLoading(true)
            await axiosInstance.get('daily_score', {
                params: {
                    patient_id: patient?.uuid,
                    days
                }
            })
                .then(res => {
                    const scoreChart = [['Date', 'Score']].concat(
                        res.data.scores.map((S: any) => {
                            return [S.date, S.score]
                        }).sort((a: any, b: any) => {
                            return a[0].localeCompare(b[0])
                        })
                    )

                    if (scoreChart.length > 0) {
                        setChartData(scoreChart)
                        setChartLoading(false)
                    }
                })
        }

        const getWeights = async () => {
            setChartLoading(true)
            await axiosInstance.get('daily_weight', {
                params: {
                    patient_id: patient?.uuid,
                    days
                }
            })
                .then(res => {
                    const scoreChart = [['Date', 'Weight']].concat(
                        res.data.scores.map((S: any) => {
                            return [S.date, S.weight]
                        }).sort((a: any, b: any) => {
                            return a[0].localeCompare(b[0])
                        })
                    )

                    if (scoreChart.length > 0) {
                        setChartData(scoreChart)
                        setChartLoading(false)
                    }
                })
        }

        const getMeals = async () => {
            setChartLoading(true)
            await axiosInstance.get('daily_meals', {
                params: {
                    patient_id: patient?.uuid,
                    days
                }
            })
                .then((res) => {
                    const mealsChart = [['Date', 'Calcium', 'Carbohydrate', 'Cholesterol', 'Energy', 'Fiber', 'Iron', 'Monounsaturated Fatty Acid', 'Polyunsaturated Fatty Acid', 'Protein', 'Saturated Fatty Acid', 'Sodium', 'Sugar', 'Total Lipid', 'Vitamin A', 'Vitamin C', 'Vitamin D']]
                        .concat(res.data.scores.map((M: any) => {
                            return [M.date, M.calcium, M.carbohydrate, M.cholesterol, M.energy, M.fiber, M.iron, M.monounsaturated_fatty_acid, M.polyunsaturated_fatty_acid, M.protein, M.saturated_fatty_acid, M.sodium, M.sugar, M.total_lipid, M.vitamin_a, M.vitamin_c, M.vitamin_d]
                        }).sort((a: any, b: any) => {
                            return a[0].localeCompare(b[0])
                        }))

                    if (selectedMacros.length > 0) {
                        const selectedNumbers = selectedMacros.map((E) => {
                            return E[0]
                        })

                        const filteredMealsChart = mealsChart.map((M) => {
                            return [
                                M[0],
                                ...(selectedNumbers.includes(1) ? [M[1]] : []),
                                ...(selectedNumbers.includes(2) ? [M[2]] : []),
                                ...(selectedNumbers.includes(3) ? [M[3]] : []),
                                ...(selectedNumbers.includes(4) ? [M[4]] : []),
                                ...(selectedNumbers.includes(5) ? [M[5]] : []),
                                ...(selectedNumbers.includes(6) ? [M[6]] : []),
                                ...(selectedNumbers.includes(7) ? [M[7]] : []),
                                ...(selectedNumbers.includes(8) ? [M[8]] : []),
                                ...(selectedNumbers.includes(9) ? [M[9]] : []),
                                ...(selectedNumbers.includes(10) ? [M[10]] : []),
                                ...(selectedNumbers.includes(11) ? [M[11]] : []),
                                ...(selectedNumbers.includes(12) ? [M[12]] : []),
                                ...(selectedNumbers.includes(13) ? [M[13]] : []),
                                ...(selectedNumbers.includes(14) ? [M[14]] : []),
                                ...(selectedNumbers.includes(15) ? [M[15]] : []),
                                ...(selectedNumbers.includes(16) ? [M[16]] : []),
                            ]
                        })
                        setChartData(filteredMealsChart)
                    } else {
                        setChartData(mealsChart)
                    }
                    setChartLoading(false)

                    if (patient) {
                        const badMeals = res.data.scores.filter((M: any) => {
                            if (M.calcium > patient.max_calcium || M.calcium < patient.min_calcium) return true
                            if (M.carbohydrate > patient.max_carbohydrate || M.carbohydrate < patient.min_carbohydrate) return true
                            if (M.cholesterol > patient.max_cholesterol || M.cholesterol < patient.min_cholesterol) return true
                            if (M.energy > patient.max_energy || M.energy < patient.min_energy) return true
                            if (M.fiber > patient.max_fiber || M.fiber < patient.min_fiber) return true
                            if (M.iron > patient.max_iron || M.iron < patient.min_iron) return true
                            if (M.monounsaturated_fatty_acid > patient.max_monounsaturated_fatty_acid || M.monounsaturated_fatty_acid < patient.min_monounsaturated_fatty_acid) return true
                            if (M.polyunsaturated_fatty_acid > patient.max_polyunsaturated_fatty_acid || M.polyunsaturated_fatty_acid < patient.min_polyunsaturated_fatty_acid) return true
                            if (M.protein > patient.max_protein || M.protein < patient.min_protein) return true
                            if (M.saturated_fatty_acid > patient.max_saturated_fatty_acid || M.saturated_fatty_acid < patient.min_saturated_fatty_acid) return true
                            if (M.sodium > patient.max_sodium || M.sodium < patient.min_sodium) return true
                            if (M.sugar > patient.max_sugar || M.sugar < patient.min_sugar) return true
                            if (M.total_lipid > patient.max_total_lipid || M.total_lipid < patient.min_total_lipid) return true
                            if (M.vitamin_a > patient.max_vitamin_a || M.vitamin_a < patient.min_vitamin_a) return true
                            if (M.vitamin_c > patient.max_vitamin_c || M.vitamin_c < patient.min_vitamin_c) return true
                            if (M.vitamin_d > patient.max_vitamin_d || M.vitamin_d < patient.min_vitamin_d) return true

                            return false
                        }).sort((a: any, b: any) => {
                            return b.date.localeCompare(a.date)
                        })

                        setWarningMeals(badMeals)
                    }
                })
        }

        if (patient) {
            setChartData([])
            if (chartShown === 'scores') {
                getScores()
            } else if (chartShown === 'weights') {
                getWeights()
            } else {
                getMeals()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartShown, days, selectedMacros])

    // Adding/Removing patient from patient group
    const handlePatientGroup = (subscribe: boolean, PGroup: { patient_id: string, group_id: string }) => {
        if (subscribe) {
            dispatch(addPatientToGroup(PGroup))
        } else {
            dispatch(deletePatientFromGroup(PGroup))
        }
        setRefreshNeeded(true)
    }

    // Logic to open and close reply modal
    const openReplyModal = (patient: PatientSimple) => {
        if (patient !== null) {
            setSelectedReplyPatient(patient);
            setReplyModalOpen(true);
        }
    }

    const handleReplyClose = () => {
        setReplyModalOpen(false);
    }

    // Send request to update nutrient allowences
    const handleMacroUpdate = (updatedPatient: Patient) => {
        dispatch(setPatientInfo(updatedPatient))
    }

    // Select which chart to display
    const setChart = (chartType: string) => {
        setChartShown(chartType)
    }


    // Sub components
    const ScoresChart = () => {
        return (
            <Chart
                chartType="LineChart"
                width="100%"
                height="600px"
                data={chartData}
                legendToggle
                options={{
                    title: "Patient Scores Over Time",
                    curveType: "none",
                    legend: { position: "bottom" },
                    lineWidth: 5
                }}
            />
        )
    }

    const WeightChart = () => {
        return (
            <Chart
                chartType="LineChart"
                width="100%"
                height="600px"
                data={chartData}
                legendToggle
                options={{
                    title: "Patient Weight Over Time",
                    curveType: "none",
                    legend: { position: "bottom" },
                    lineWidth: 5
                }}
            />
        )
    }

    const MealsChart = () => {
        return (
            <Chart
                chartType="LineChart"
                width="100%"
                height="600px"
                data={chartData}
                options={{
                    title: "Patient Meals Over Time",
                    subtitle: 'test',
                    curveType: "none",
                    legend: { position: "bottom" },
                    lineWidth: 5
                }}
            />
        )
    }

    return (
        <Modal
            open={props.openModal}
            onClose={props.onClose}
            sx={{ overflow: 'scroll' }}
        >
            <Container sx={{ paddingTop: "1%" }}>
                <ModalReply currentP={selectedReplyPatient} openModal={replyModalOpen} onClose={handleReplyClose} selectPatient={setSelectedReplyPatient} patientLocked={true} />
                <Card>
                    {
                        patient ?
                            <div>
                                <CardHeader title={patient.first_name.concat(" ", patient.last_name, "'s Profile:")} action={
                                    <IconButton aria-label="settings" onClick={props.onClose}>
                                        <CloseIcon />
                                    </IconButton>
                                } />
                                <CardContent>
                                    <Divider />
                                    <Typography component="h1" variant="h6">Patient Info</Typography>
                                    <Typography variant="body1">Gender: {patient.gender ? patient.gender : 'N/A'}</Typography>
                                    <Typography variant="body1">Height: {patient.height ? patient.height.toString() : 'N/A'}</Typography>
                                    <Typography variant="body1">Birth Date: {patient.birth_date ? new Date(patient.birth_date).toLocaleDateString() : 'N/A'}</Typography>
                                    <Divider sx={{ mt: 2 }} />

                                    <Typography component="h1" variant="h6">Groups</Typography>
                                    <ButtonGroup fullWidth variant="contained">
                                        {
                                            groups?.map((X, i) => {
                                                return (
                                                    <Button
                                                        key={i}
                                                        color={X.subscribed ? 'success' : 'warning'}
                                                        onClick={() => {
                                                            if (X.subscribed) {
                                                                handlePatientGroup(false, { patient_id: patient.uuid, group_id: X.id })
                                                            } else {
                                                                handlePatientGroup(true, { patient_id: patient.uuid, group_id: X.id })
                                                            }
                                                        }}
                                                    >
                                                        {X.name}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </ButtonGroup>
                                    <Divider sx={{ mt: 2 }} />

                                    <Typography component="h1" variant="h6">Charts</Typography>
                                    <ButtonGroup fullWidth variant="contained" color="info" disabled={chartLoading}>
                                        <Button onClick={() => setChart('scores')} disabled={chartShown === 'scores'}>Daily Scores</Button>
                                        <Button onClick={() => setChart('weights')} disabled={chartShown === 'weights'}>Daily Weights</Button>
                                        <Button onClick={() => setChart('meals')} disabled={chartShown === 'meals'}>Daily Meals</Button>
                                    </ButtonGroup>
                                    {
                                        chartShown === 'meals' ?
                                            <Container sx={{ mt: 3, mb: 0 }}>
                                                <Autocomplete
                                                    fullWidth
                                                    multiple={true}
                                                    options={macroOptions}
                                                    getOptionLabel={(option) => option[1].toString()}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Macros to View"
                                                        />
                                                    )}
                                                    onChange={(e, v) => setSelectedMacros(v)}
                                                />
                                            </Container>
                                            :
                                            null
                                    }
                                    {
                                        !chartLoading ?
                                            (chartData.length > 1) ?
                                                chartShown === 'scores' ?
                                                    <ScoresChart />
                                                    :
                                                    chartShown === 'meals' ?
                                                        <div>
                                                            <MealsChart />
                                                            <Typography variant="h6" sx={{ mb: 2 }}>Meals Outside of Nutrient Allotment</Typography>
                                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                                {
                                                                    warningMeals.map((M, index) => {
                                                                        return (
                                                                            <Grid item xs={3} key={index}>
                                                                                <Card>
                                                                                    <CardHeader title={M.date} />
                                                                                    <CardContent>
                                                                                        <ButtonGroup fullWidth orientation="vertical" variant="text" size="small">
                                                                                            <Button color={(M.calcium > patient.max_calcium || M.calcium < patient.min_calcium) ? 'error' : 'primary'}>Calcium: {M.calcium}mg</Button>
                                                                                            <Button color={(M.carbohydrate > patient.max_carbohydrate || M.carbohydrate < patient.min_carbohydrate) ? 'error' : 'primary'}>Calcium: {M.carbohydrate}g</Button>
                                                                                            <Button color={(M.cholesterol > patient.max_cholesterol || M.cholesterol < patient.min_cholesterol) ? 'error' : 'primary'}>Cholesterol: {M.cholesterol} mg</Button>
                                                                                            <Button color={(M.energy > patient.max_energy || M.energy < patient.min_energy) ? 'error' : 'primary'}>Energy: {M.energy} kcal</Button>
                                                                                            <Button color={(M.fiber > patient.max_fiber || M.fiber < patient.min_fiber) ? 'error' : 'primary'}>Fiber: {M.fiber} g</Button>
                                                                                            <Button color={(M.iron > patient.max_iron || M.iron < patient.min_iron) ? 'error' : 'primary'}>Iron: {M.iron} mg</Button>
                                                                                            <Button color={(M.monounsaturated_fatty_acid > patient.max_monounsaturated_fatty_acid || M.monounsaturated_fatty_acid < patient.min_monounsaturated_fatty_acid) ? 'error' : 'primary'}>Monosaturated Fatty Acid: {M.monounsaturated_fatty_acid} g</Button>
                                                                                            <Button color={(M.polyunsaturated_fatty_acid > patient.max_polyunsaturated_fatty_acid || M.polyunsaturated_fatty_acid < patient.min_polyunsaturated_fatty_acid) ? 'error' : 'primary'}>Polyunsaturated Fatty Acid: {M.polyunsaturated_fatty_acid} g</Button>
                                                                                            <Button color={(M.protein > patient.max_protein || M.protein < patient.min_protein) ? 'error' : 'primary'}>Protein: {M.protein} g</Button>
                                                                                            <Button color={(M.saturated_fatty_acid > patient.max_saturated_fatty_acid || M.saturated_fatty_acid < patient.min_saturated_fatty_acid) ? 'error' : 'primary'}>Saturated Fatty Acid: {M.saturated_fatty_acid} g</Button>
                                                                                            <Button color={(M.sodium > patient.max_sodium || M.sodium < patient.min_sodium) ? 'error' : 'primary'}>Sodium: {M.sodium} mg</Button>
                                                                                            <Button color={(M.sugar > patient.max_sugar || M.sugar < patient.min_sugar) ? 'error' : 'primary'}>Sugar: {M.sugar} g</Button>
                                                                                            <Button color={(M.total_lipid > patient.max_total_lipid || M.total_lipid < patient.min_total_lipid) ? 'error' : 'primary'}>Total Lipid: {M.total_lipid} g</Button>
                                                                                            <Button color={(M.vitamin_a > patient.max_vitamin_a || M.vitamin_a < patient.min_vitamin_a) ? 'error' : 'primary'}>Vitamin A: {M.vitamin_a} mg</Button>
                                                                                            <Button color={(M.vitamin_c > patient.max_vitamin_c || M.vitamin_c < patient.min_vitamin_c) ? 'error' : 'primary'}>Vitamin C: {M.vitamin_c} ug</Button>
                                                                                            <Button color={(M.vitamin_d > patient.max_vitamin_d || M.vitamin_d < patient.min_vitamin_d) ? 'error' : 'primary'}>Vitamin D: {M.vitamin_d} ug</Button>
                                                                                        </ButtonGroup>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </div>
                                                        :
                                                        <WeightChart />
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', height: "600px", direction: "column", alignItems: "center" }}>
                                                    <Typography variant="body1">No Values Reported</Typography>
                                                </Box>
                                            :
                                            <Box sx={{ display: 'flex', justifyContent: 'center', height: "600px", direction: "column", alignItems: "center" }}>
                                                <CircularProgress />
                                            </Box>
                                    }

                                    <ButtonGroup fullWidth variant="contained" color="secondary" disabled={!(chartData.length > 1) || chartLoading}>
                                        <Button disabled={days === 3} onClick={() => setDays(3)}>Last 3 Days</Button>
                                        <Button disabled={days === 7} onClick={() => setDays(7)}>Last 7 Days</Button>
                                        <Button disabled={days === 30} onClick={() => setDays(30)}>Last 30 Days</Button>
                                        <Button disabled={days === null} onClick={() => setDays(null)}>All</Button>
                                    </ButtonGroup>
                                    <Divider sx={{ mt: 2 }} />

                                    <Typography component="h1" variant="h6">Adjust Nutrient Values</Typography>
                                    <DailyNutrientTable patient={patient} updateCall={handleMacroUpdate} />
                                    <Divider />
                                    <div>

                                        <Typography component="h1" variant="h6">Notifications</Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Time</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Message</TableCell>
                                                        <TableCell>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        notifications ?
                                                            notifications.map((N, index) => {
                                                                const pSimple: PatientSimple = {
                                                                    id: patient.uuid,
                                                                    ins_id: patient.ins_id,
                                                                    first_name: patient.first_name,
                                                                    last_name: patient.last_name,
                                                                    email: patient.email,
                                                                    gender: patient.gender,
                                                                    height: patient.height?.toString(),
                                                                    birth_date: patient.birth_date
                                                                }
                                                                const date = new Date(N.timestamp)

                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{date.toLocaleDateString()}</TableCell>
                                                                        <TableCell>{date.toLocaleTimeString()}</TableCell>
                                                                        <TableCell>{N.notification_type}</TableCell>
                                                                        <TableCell>{N.text}</TableCell>
                                                                        <TableCell>
                                                                            <ButtonGroup size="small" variant="outlined">
                                                                                <Button onClick={() => openReplyModal(pSimple)}>
                                                                                    Reply
                                                                                </Button>
                                                                                <Button color="error" onClick={() => dispatch(deleteNotification(N.notification_id))}>Delete</Button>
                                                                            </ButtonGroup>

                                                                        </TableCell>
                                                                    </TableRow>

                                                                )

                                                            })
                                                            :
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography component="h1" >Nothing Yet!</Typography>
                                                                </TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>

                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>

                                </CardContent>
                            </div>
                            :
                            <h1>LOADING</h1>
                    }
                </Card>
            </Container>
        </Modal>
    )

}

export default ModalPatientProfile