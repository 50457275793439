import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

import { getInstitutions } from '../apiCalls'

export interface InsInfoType {
    name: string,
    id: string
}

interface InstitutionState {
    isLoading: boolean
    institutions: Array<InsInfoType>
}

const initialState: InstitutionState = {
    isLoading: false,
    institutions: []
}

export const institutionSlice = createSlice({
    name: 'institution',
    initialState,
    reducers: {
        clearInstitutions: (state) => {
            state.institutions = []
        }
    },
    extraReducers: {
        [getInstitutions.pending.type]: (state) => {
            state.isLoading = true
        },
        [getInstitutions.fulfilled.type]: (state, action: PayloadAction<Array<InsInfoType>>) => {
            state.institutions = action.payload

            state.isLoading = false
        },
        [getInstitutions.rejected.type]: (state) => {
            state.isLoading = false
        },
    }
})

export const { clearInstitutions } = institutionSlice.actions

// Selectors for accessing that part of the state using useSelector within components
export const selectInstitutions = (state: RootState) => state.institutions.institutions

export default institutionSlice.reducer