import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

import authReducer from './slices/authSlice'
import errorReducer from './slices/errorSlice'
import patientDataReducer from './slices/patientDataSlice'
import institutionReducer from './slices/institutionSlice'
import adminPageReducer from './slices/adminPageSlice'

const persistConfig = {
    key: 'root',
    storage
}

const persistedAuth = persistReducer(persistConfig, authReducer)

export const store = configureStore({
    reducer: {
        auth: persistedAuth,
        error: errorReducer,
        patientData: patientDataReducer,
        institutions: institutionReducer,
        adminPage: adminPageReducer
    }
})

export const persistor = persistStore(store)


// AK: not sure what these do but will likely find out soon

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch