import { Button, Input, InputAdornment } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useState } from "react"
import { Patient } from "../../slices/patientDataSlice"

/* 
    Table Component to show patient's current nutrient allowences
    and allows editing to them
*/
function DailyNutrientTable(props: { patient: Patient, updateCall: (updatedPatient: Patient) => void }) {
    const [updatedPatient, setUpdatedpatient] = useState<Patient>(props.patient)

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Nutrient</TableCell>
                        <TableCell>Min-Allowed</TableCell>
                        <TableCell>Max-Allowed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Energy</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_energy.toString()}
                                endAdornment={<InputAdornment position="end">kcal</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_energy: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_energy.toString()}
                                endAdornment={<InputAdornment position="end">kcal</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_energy: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Protein</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_protein.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_protein: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_protein.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_protein: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Lipid</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_total_lipid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_total_lipid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_total_lipid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_total_lipid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Carbohydrate</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_carbohydrate.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_carbohydrate: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_carbohydrate.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_carbohydrate: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Fiber</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_fiber.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_fiber: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_fiber.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_fiber: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sugar</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_sugar.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_sugar: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_sugar.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_sugar: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Calcium</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_calcium.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_calcium: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_calcium.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_calcium: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Iron</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_iron.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_iron: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_iron.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_iron: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sodium</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_sodium.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_sodium: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_sodium.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_sodium: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Vitamin A</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_vitamin_a.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_vitamin_a: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_vitamin_a.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_vitamin_a: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Vitamin C</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_vitamin_c.toString()}
                                endAdornment={<InputAdornment position="end">ug</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_vitamin_c: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_vitamin_c.toString()}
                                endAdornment={<InputAdornment position="end">ug</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_vitamin_c: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Vitamin D</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_vitamin_d.toString()}
                                endAdornment={<InputAdornment position="end">ug</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_vitamin_d: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_vitamin_d.toString()}
                                endAdornment={<InputAdornment position="end">ug</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_vitamin_d: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Saturated Fatty Acid</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_saturated_fatty_acid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_saturated_fatty_acid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_saturated_fatty_acid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_saturated_fatty_acid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Monounsaturated Fatty Acid</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_monounsaturated_fatty_acid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_monounsaturated_fatty_acid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_monounsaturated_fatty_acid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_monounsaturated_fatty_acid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Polyunsaturated Fatty Acid</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_monounsaturated_fatty_acid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_monounsaturated_fatty_acid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_monounsaturated_fatty_acid.toString()}
                                endAdornment={<InputAdornment position="end">g</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_monounsaturated_fatty_acid: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Cholesterol</TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.min_cholesterol.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        min_cholesterol: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                type="number"
                                placeholder={props.patient.max_cholesterol.toString()}
                                endAdornment={<InputAdornment position="end">mg</InputAdornment>}
                                onChange={(e) => {
                                    const update = {
                                        ...updatedPatient,
                                        max_cholesterol: Number(e.target.value)
                                    }

                                    setUpdatedpatient(update)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table >
            <Button fullWidth={true} size="large" onClick={() => props.updateCall(updatedPatient)}>Save Changes</Button>
        </TableContainer >
    )
}

export default DailyNutrientTable