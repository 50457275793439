import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPatientGroups, getPatientsInGroup, subscribeToPatientGroup, unsubscribeFromPatientGroup } from "../../apiCalls"
import { Group } from "../../slices/patientDataSlice"
import { RootState } from "../../store"
import { Person } from "@mui/icons-material"

/* 
    Component that shows the patient groups for the institution that is
    selected

    Loaded on component mount:
    The patient groups for the current institution

    Loaded on patient group selection:
    Patients in that group with simple info
*/
function InstitutionDisplay(props: { openDetailedInfo: any }) {
    const dispatch = useDispatch()

    const institution = useSelector((state: RootState) => state.patientData.selectedInstitution)
    const patientGroups = useSelector((state: RootState) => state.patientData.groups)
    const patients = useSelector((state: RootState) => state.patientData.patientsSimple)
    const isPatientsLoading = useSelector((state: RootState) => state.patientData.patientsLoading)

    const [filteredGroups, setFilteredGroups] = useState<Group[]>([])
    const [onlySubscribed, setOnlySubscribed] = useState(true)

    const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)

    useEffect(() => {
        if (institution) {
            dispatch(getPatientGroups(institution.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institution])

    useEffect(() => {
        if (selectedGroup) {
            dispatch(getPatientsInGroup(selectedGroup.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroup])

    useEffect(() => {
        if (patientGroups) {
            let temp
            if (onlySubscribed) {
                temp = patientGroups.filter((G) => G.subscribed === true).slice()
            } else {
                temp = patientGroups.slice()
            }

            temp.sort((a, b) => {
                if (a.subscribed && b.subscribed) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                } else if (a.subscribed) {
                    return -1
                } else {
                    return 1
                }
            })

            setFilteredGroups(temp)
        }
    }, [patientGroups, onlySubscribed])

    const interactWithGroup = (subscribe: boolean, group_id: string) => {
        if (subscribe) {
            dispatch(subscribeToPatientGroup(group_id))
        } else {
            dispatch(unsubscribeFromPatientGroup(group_id))
        }
    }

    if (institution) {
        return (
            <Container sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="h3" >{institution.name}</Typography >
                    <Box sx={{ marginLeft: "auto" }}>
                        <Button variant="contained" size="small" color={onlySubscribed ? "warning" : "info"} onClick={() => setOnlySubscribed(!onlySubscribed)}>{onlySubscribed ? 'Show All Groups' : 'Show Subscribed Groups'}</Button>
                    </Box>
                </Box>
                <Divider sx={{ mb: 4 }} />
                {
                    filteredGroups.map((G, index) => {

                        const PList = () => {
                            if (!isPatientsLoading) {
                                return (
                                    <List>
                                        {patients.map((P, i) => {
                                            return (
                                                <ListItem key={i} disablePadding>
                                                    <ListItemButton onClick={() => props.openDetailedInfo(P.id)}>
                                                        <ListItemIcon>
                                                            <Person />
                                                        </ListItemIcon>
                                                        <ListItemText primary={P.last_name.concat(', ', P.first_name)} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                )
                            } else {
                                return (
                                    <CircularProgress />
                                )
                            }
                        }

                        return (
                            <Accordion key={index} expanded={selectedGroup === G} onChange={() => setSelectedGroup(G)}>
                                <AccordionSummary>
                                    <Typography variant="h4" sx={{ width: '33%', flexShrink: 0 }}>
                                        {G.name}
                                    </Typography>
                                    <Button
                                        disabled={G.loading}
                                        variant="contained"
                                        color={G.subscribed ? 'error' : 'success'}
                                        sx={{ marginLeft: "auto" }}
                                        onClick={() => {
                                            interactWithGroup(!G.subscribed, G.id)
                                        }}
                                    >
                                        {G.subscribed ? 'Unsubscribe' : 'Subscribe'}
                                    </Button>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PList />
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </Container >
        )
    } else {
        return (
            <h1>LOADING</h1>
        )
    }
}

export default InstitutionDisplay