import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useDispatch } from 'react-redux';
import { setError, clearError } from '../slices/errorSlice';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Alert, FormControlLabel, Switch } from '@mui/material';
import { axiosInstance } from '../apiCalls';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://sciencella.com/">
        Sciencella.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();


/* 
    Page for the dietitian to reset their password
    If page is accessed without the access_token parameter, then
    page asks for user to input their email address
    If page is accessed with the access_token parameter, then
    page asks for new password
*/
export default function ResetPage() {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const access_token = useState(searchParams.get('access_token'))

  const [showPassword, setShowPassword] = useState(false)

  const [message, setMessage] = useState('')

  // Function to check if input values are valid and sends the API requests
  const reset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget)

    if (access_token[0]) {
      const password1 = String(data.get('password1'))
      const password2 = String(data.get('password2'))

      if (!password1 || !password2) {
        dispatch(setError('Field left blank'))
      } else if (password1 !== password2) {
        dispatch(setError('Passwords do not match'))
      } else {
        dispatch(clearError())

        await axiosInstance.post('user/reset', { access_token: access_token[0], new_password: password1 })
          .then(() => {
            setMessage('Password reset successfully!')
          })
      }

    } else {
      const email = String(data.get('email'))

      if (email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        dispatch(clearError())

        await axiosInstance.post('user/forgot', { email })
          .then(() => {
            setMessage('Email sent successfully!')
          })
      } else {
        dispatch(setError('Please enter a valid email address'))
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <QuestionMarkIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box name="form" component="form" onSubmit={reset} noValidate sx={{ mt: 1 }}>
            {
              access_token[0] ?
                <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password1"
                    label="New Password"
                    name="password1"
                    type={showPassword ? 'text' : 'password'}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password2"
                    label="Confirm New Password"
                    name="password2"
                    type={showPassword ? 'text' : 'password'}
                  />
                  <FormControlLabel control={<Switch onChange={(e) => setShowPassword(e.target.checked)} />} label='Show Password' />
                </div>
                :
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
            }
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={message ? true : false}
            >
              {access_token[0] ? 'Reset Password' : 'Send A Recovery Email'}
            </Button>
            <Grid container>
              {
                message ?
                  <Grid container>
                    <Grid item sx={{ width: '100%' }}>
                      <Alert severity="success">{message}</Alert>
                    </Grid>
                  </Grid>
                  :
                  null
              }
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Have an account with us? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
