import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { Box, Button, ButtonGroup, Container, Divider, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { axiosInstance, deleteNotification } from "../../apiCalls"
import { PatientSimple, Notification } from "../../slices/patientDataSlice"
import { RootState } from "../../store"
import ModalReply from "./NotificationReply"

/* 
    Page that displays all notifications relating to the dietitian

    Loaded on component mount:
    Simple patient info for each patient who sent a notification
*/
function NotificationPage() {
    const dispatch = useDispatch()

    const notifications = useSelector((state: RootState) => state.patientData.notifications)
    const notificationsLoading = useSelector((state: RootState) => state.patientData.notificationsLoading)
    const [patients, setPatients] = useState<any>()
    const [selectPatient, setSelectPatiant] = useState<PatientSimple | null>(null)
    const [notificationModalOpen, setNotificationModalOpen] = useState(false)

    const [searchFilter, setSearchFilter] = useState('')
    const [sentFilter, setSentFilter] = useState('all')
    const [visibleNotifications, setVisibleNotifications] = useState<Notification[]>([])

    const handleClose = () => {
        setNotificationModalOpen(false)
        setSelectPatiant(null)
    }

    const setPatient = (P: PatientSimple | null) => {
        setSelectPatiant(P)
    }

    useEffect(() => {
        const patient_ids = notifications.map((N) => N.patient_id)

        const getPatients = async () => {
            await axiosInstance.post('patientuser/batch', {
                patients: patient_ids.map((patient_id: string) => {
                    return { patient_id }
                }),
                search_term: ['first_name', 'last_name', 'email', 'uuid', 'ins_id']
            })
                .then((res) => {

                    const pDict = res.data.patients.reduce((A: any, P: any) => {
                        return {
                            ...A,
                            [P.uuid]: {
                                id: P.uuid,
                                email: P.email,
                                first_name: P.first_name,
                                last_name: P.last_name,
                                ins_id: P.ins_id
                            }
                        }
                    }, {})

                    setPatients(pDict)
                })
        }

        setSearchFilter('')
        getPatients()

        const tempNotifs = notifications.slice().sort((a, b) => {
            return b.timestamp.localeCompare(a.timestamp)
        })

        setVisibleNotifications(tempNotifs)

    }, [notifications])

    useEffect(() => {
        if (patients) {
            setVisibleNotifications(notifications.filter((N) => {
                const P = patients[N.patient_id]
                const name = P.first_name.concat(' ', P.last_name)

                if (sentFilter === 'all') {
                    return name.includes(searchFilter)
                } else if (sentFilter === 'sent') {
                    return (name.includes(searchFilter) && N.to_patient)
                } else {
                    return (name.includes(searchFilter) && !N.to_patient)
                }
            }).sort((a, b) => {
                return b.timestamp.localeCompare(a.timestamp)
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter, sentFilter])

    const clickReply = (pid: string) => {
        if (patients) {
            setSelectPatiant(patients[pid])
            setNotificationModalOpen(true)
        }
    }

    const deleteNotif = (nid: string) => {
        dispatch(deleteNotification(nid))
    }

    return (
        <Container sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="h3">Notifications</Typography>
                <Box sx={{ marginLeft: "auto" }}>
                    <Button variant="contained" size="small" onClick={() => setNotificationModalOpen(true)}>Create Message</Button>
                </Box>
            </Box>

            <Divider sx={{ mb: 2 }} />
            <Grid container>
                <Grid item xs={9}>
                    <TextField
                        fullWidth
                        label='Search Patient by Name'
                        onChange={(e) => setSearchFilter(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        fullWidth
                        label='Filter'
                        value={sentFilter}
                        onChange={(e) => setSentFilter(e.target.value)}
                        variant='filled'
                    >
                        <MenuItem value='all'>View All</MenuItem>
                        <MenuItem value='sent'>Show Sent Messages</MenuItem>
                        <MenuItem value='received'>Show Received Messages</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <TableContainer>
                <Table>
                    <colgroup>
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '7%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '17%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '45%' }} />
                        <col style={{ width: '5%' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sent/Recieved</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Patient</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !notificationsLoading ?
                                visibleNotifications.map((notif, index) => {
                                    const date = new Date(notif.timestamp)

                                    if (patients) {
                                        const P = patients[notif.patient_id]

                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {
                                                        notif.to_patient ?
                                                            <Button startIcon={<ArrowBack />} variant='contained' color="secondary" disabled>Sent</Button>
                                                            :
                                                            <Button endIcon={<ArrowForward />} variant='contained' color="secondary" disabled>Received</Button>
                                                    }
                                                </TableCell>
                                                <TableCell>{date.toLocaleDateString()}</TableCell>
                                                <TableCell>{date.toLocaleTimeString()}</TableCell>
                                                <TableCell>
                                                    {patients ?
                                                        P ?
                                                            P.first_name.concat(' ', P.last_name)
                                                            :
                                                            'Loading'
                                                        :
                                                        'Loading'}
                                                </TableCell>
                                                <TableCell>{notif.notification_type}</TableCell>
                                                <TableCell>{notif.text}</TableCell>
                                                <TableCell>
                                                    <ButtonGroup size="small" fullWidth>
                                                        {notif.to_patient ? null : <Button onClick={() => clickReply(notif.patient_id)}>Reply</Button>}
                                                        <Button color="error" onClick={() => deleteNotif(notif.notification_id)}>Delete</Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    } else {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {
                                                        notif.to_patient ?
                                                            <Button startIcon={<ArrowBack />} variant='contained' color="secondary" disabled>Sent</Button>
                                                            :
                                                            <Button endIcon={<ArrowForward />} variant='contained' color="secondary" disabled>Received</Button>
                                                    }
                                                </TableCell>
                                                <TableCell>{date.toLocaleDateString()}</TableCell>
                                                <TableCell>{date.toLocaleTimeString()}</TableCell>
                                                <TableCell>Loading</TableCell>
                                                <TableCell>{notif.notification_type}</TableCell>
                                                <TableCell>{notif.text}</TableCell>
                                                <TableCell>
                                                    <ButtonGroup size="small" fullWidth>
                                                        {notif.to_patient ? null : <Button onClick={() => clickReply(notif.patient_id)}>Reply</Button>}
                                                        <Button disabled color="error">Delete</Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                })
                                :
                                <h1>LOADING</h1>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalReply currentP={selectPatient} openModal={notificationModalOpen} onClose={handleClose} selectPatient={setPatient} patientLocked={false} />
        </Container>
    )
}

export default NotificationPage