import { Person } from "@mui/icons-material"
import { Box, Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllPatients } from "../../apiCalls"

import { PatientSimple } from '../../slices/patientDataSlice'
import { RootState } from "../../store"

interface InsPatientGroup {
    ins_name: string
    access: boolean
    patients: PatientSimple[]
}

/* 
    Component to show all patients in all institutions that the dietitian is a part of
    
    Loaded on component mount:
    All Patients that the dietitian has access to with simple information
*/
function AllPatientsDisplay(props: { openDetailedInfo: any }) {
    const dispatch = useDispatch()

    const isPatientsLoading = useSelector((state: RootState) => state.patientData.patientsLoading)
    const patients = useSelector((state: RootState) => state.patientData.patientsSimple)
    const institutions = useSelector((state: RootState) => state.patientData.institutions)

    const [sortedPatients, setSortedPatients] = useState<InsPatientGroup[]>([])

    const [searchTerm, setSearchTerm] = useState<string>('')

    useEffect(() => {
        dispatch(getAllPatients())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        sortPatients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patients])

    const sortPatients = () => {
        let filtered: InsPatientGroup[] = []

        institutions.forEach((ins) => {
            filtered.push({
                ins_name: ins.name,
                access: ins.access,
                patients: patients.filter((P) => P.ins_id === ins.id)
            })
        })

        setSortedPatients(filtered)
    }

    return (
        <Container maxWidth="xl" sx={{ padding: '2rem' }}>
            {!isPatientsLoading && sortedPatients ?
                <div>
                    <Container>
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <TextField label="Search" variant="outlined" size="small" onChange={(e) => setSearchTerm(e.target.value)} />
                        </Box>
                        {
                            sortedPatients.map((X: InsPatientGroup, index: number) => {
                                return (
                                    <Container key={index}>
                                        <Typography variant="h4">{X.ins_name}</Typography>
                                        <Divider sx={{ mb: 4 }} />
                                        {
                                            X.access ?
                                                <PatientCards patients={X.patients} openDetailedInfo={props.openDetailedInfo} searchTerm={searchTerm} />
                                                :
                                                <Typography variant="h6">No Access Granted</Typography>
                                        }
                                        <Divider sx={{ mt: 4, mb: 4 }} />
                                    </Container>
                                )
                            })
                        }
                    </Container>
                </div>
                :
                <h1>LOADING</h1>
            }
        </Container>
    )
}

function PatientCards(props: { patients: PatientSimple[], openDetailedInfo: any, searchTerm: string }) {

    const patientsToShow = props.patients.filter((P) => {
        return P.first_name.concat(' ', P.last_name).toLowerCase().includes(props.searchTerm)
    })

    return (
        <List>
            {patientsToShow.map((X: PatientSimple, index: number) => {
                return (
                    <ListItem key={index} disablePadding>
                        <ListItemButton onClick={() => props.openDetailedInfo(X.id)}>
                            <ListItemIcon>
                                <Person />
                            </ListItemIcon>
                            <ListItemText primary={X.last_name.concat(', ', X.first_name)} />
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    )
}


export default AllPatientsDisplay