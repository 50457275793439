import { AppBar, Autocomplete, Box, Button, ButtonGroup, Container, Divider, TextField, Toolbar, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from '../store';
import { useEffect, useState } from 'react';
import { getInstitutionsDietician, getNotifications, getPatientInfo, logoutUser } from '../apiCalls';
import { clearInstitution, clearPatient, Institution, selectInstitution } from '../slices/patientDataSlice';
import AllPatientsDisplay from './DashboardComponents/AllPatientsDisplay';
import ModalPatientProfile from './DashboardComponents/ModalPatientProfile';
import InstitutionDisplay from "./DashboardComponents/InstitutionDisplay";
import NotificationPage from "./DashboardComponents/NotificationPage";

/* 
    Main Page for Dietician to use
    
    Loaded on component mount:
    Dietitians Notifications
    Institutions the Dietitian is a part of
*/
function Dashboard() {
    const dispatch = useDispatch()

    const isInstitutionsLoading = useSelector((state: RootState) => state.patientData.institutionsLoading)
    const institutions = useSelector((state: RootState) => state.patientData.institutions)
    const selectedInstitution = useSelector((state: RootState) => state.patientData.selectedInstitution)

    const [detailModalOpen, setDetailModalOpen] = useState(false)
    const [notificationPageSelected, setNotificationPageSelected] = useState(false)

    const openPatientModal = (id: string) => {
        if (id !== null) {
            dispatch(getPatientInfo(id))
            setDetailModalOpen(true)
        }
    }

    const handleClose = () => {
        dispatch(clearPatient())
        setDetailModalOpen(false)
    }

    useEffect(() => {
        dispatch(getInstitutionsDietician())
        dispatch(getNotifications())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (_: any, value: Institution | null) => {
        dispatch(selectInstitution(value))
    }

    return (
        <div style={{ minHeight: '100vh' }}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h5" color="inherit" component="div" sx={{ mr: 3 }}>
                        Sciencella Dietician Dashboard
                    </Typography>
                    <Autocomplete
                        disabled={isInstitutionsLoading || notificationPageSelected}
                        size='small'
                        disablePortal
                        id="institution-auto-complete"
                        options={institutions}
                        sx={{ width: 300, m: 1 }}
                        getOptionLabel={(option) => option.name}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    label='Institution Select'
                                    variant="outlined"
                                    fullWidth
                                />
                            )
                        }}
                        onChange={handleChange}
                    />
                    <ButtonGroup variant="contained" color="info" size="small">
                        <Button disabled={notificationPageSelected} onClick={() => dispatch(clearInstitution())}>Show Patients for All Institutions</Button>
                        <Button onClick={() => setNotificationPageSelected(!notificationPageSelected)}>{notificationPageSelected ? 'Back to Patients' : 'Notifications'}</Button>
                    </ButtonGroup>
                    <Button color="inherit" onClick={() => dispatch(logoutUser())} sx={{ marginLeft: "auto" }}>Logout</Button>
                </Toolbar>
            </AppBar>
            <Box>
                {
                    !notificationPageSelected ?
                        !isInstitutionsLoading ?
                            selectedInstitution ?
                                selectedInstitution.access ?
                                    <Container>
                                        <InstitutionDisplay openDetailedInfo={openPatientModal} />
                                    </Container>
                                    :
                                    <Container>
                                        <Typography variant="h3">
                                            {selectedInstitution.name}
                                        </Typography>
                                        <Divider />
                                        <Typography variant="h6">
                                            You do not have access to patients in this institution. Contact institution admin.
                                        </Typography>
                                    </Container>
                                :
                                <AllPatientsDisplay openDetailedInfo={openPatientModal} />
                            :
                            <Container>
                                <Typography variant="h4">
                                    Loading
                                </Typography>
                            </Container>
                        :
                        <NotificationPage />
                }
            </Box>
            <ModalPatientProfile openModal={detailModalOpen} onClose={handleClose} />
        </div>
    )
}

export default Dashboard