import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllPatients, getInstitutionsDietician, getNotifications, getPatientGroups, getPatientInfo, getPatientsInGroup, setPatientInfo } from '../apiCalls'
import type { RootState } from '../store'

export interface Patient {
    ins_id: string,
    uuid: string,
    email: string,
    first_name: string,
    last_name: string
    gender: string,
    group: string,
    birth_date: string,
    height: number,
    min_energy: number,
    max_energy: number,
    min_protein: number,
    max_protein: number,
    min_total_lipid: number,
    max_total_lipid: number,
    min_carbohydrate: number,
    max_carbohydrate: number,
    min_fiber: number,
    max_fiber: number,
    min_sugar: number,
    max_sugar: number,
    min_calcium: number,
    max_calcium: number,
    min_iron: number,
    max_iron: number,
    min_sodium: number,
    max_sodium: number,
    min_vitamin_a: number,
    max_vitamin_a: number,
    min_vitamin_c: number,
    max_vitamin_c: number,
    min_vitamin_d: number,
    max_vitamin_d: number,
    min_saturated_fatty_acid: number,
    max_saturated_fatty_acid: number,
    min_monounsaturated_fatty_acid: number,
    max_monounsaturated_fatty_acid: number,
    min_polyunsaturated_fatty_acid: number,
    max_polyunsaturated_fatty_acid: number,
    min_cholesterol: number,
    max_cholesterol: number
}

export interface PatientSimple {
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    ins_id: string,
    gender: string,
    height: string,
    birth_date: string
}

export interface Group {
    id: string
    name: string
    subscribed: boolean
    loading: boolean
}

export interface Institution {
    id: string
    name: string
    access: boolean
}

export interface Notification {
    notification_id: string
    patient_id: string
    timestamp: string
    notification_type: string
    text: string
    to_patient: boolean
}

interface PatientsState {
    notifications: Notification[]
    institutions: Institution[]
    selectedInstitution: Institution | null
    groups: Group[]
    patientsSimple: PatientSimple[]
    selectedPatient: Patient | null
    institutionsLoading: boolean
    patientsLoading: boolean
    patientGroupsLoading: boolean
    patientLoading: boolean
    notificationsLoading: boolean
}

const initialState: PatientsState = {
    notifications: [],
    institutions: [],
    selectedInstitution: null,
    groups: [],
    patientsSimple: [],
    selectedPatient: null,
    institutionsLoading: false,
    patientsLoading: false,
    patientGroupsLoading: false,
    patientLoading: false,
    notificationsLoading: false
}

export const patientDataSlice = createSlice({
    name: 'patientData',
    initialState,
    reducers: {
        selectInstitution: (state, action: PayloadAction<Institution | null>) => {
            state.selectedInstitution = action.payload
        },
        clearInstitution: (state) => {
            state.selectedInstitution = null
        },
        clearPatient: (state) => {
            state.selectedPatient = null
        },
        clearPatientData: (state) => {
            state.institutions = []
            state.groups = []
            state.patientsSimple = []
            state.selectedPatient = null
            state.institutionsLoading = false
            state.patientsLoading = false
            state.patientGroupsLoading = false
            state.patientLoading = false
        }
    },
    extraReducers: {
        [getInstitutionsDietician.pending.type]: (state) => {
            state.institutionsLoading = true
        },
        [getInstitutionsDietician.fulfilled.type]: (state, action: PayloadAction<Institution[]>) => {
            state.institutions = action.payload.sort((a, b) => {
                if ((a.access && b.access) || (!a.access && !b.access)) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                } else if (a.access) {
                    return -1
                } else {
                    return 1
                }
            })
            if (state.institutions.length > 0) {
                state.selectedInstitution = state.institutions[0]
            }
            state.institutionsLoading = false
        },
        [getInstitutionsDietician.rejected.type]: (state) => {
            state.institutionsLoading = false
        },
        [getAllPatients.pending.type]: (state) => {
            state.patientsLoading = true
        },
        [getAllPatients.fulfilled.type]: (state, action: PayloadAction<PatientSimple[]>) => {
            state.patientsSimple = action.payload
            state.patientsLoading = false
        },
        [getAllPatients.rejected.type]: (state) => {
            state.patientsLoading = false
        },
        [getPatientInfo.pending.type]: (state) => {
            state.patientLoading = true
        },
        [getPatientInfo.fulfilled.type]: (state, action: PayloadAction<Patient>) => {
            state.selectedPatient = action.payload
            state.patientLoading = false
        },
        [getPatientInfo.rejected.type]: (state) => {
            state.patientLoading = false
        },
        [getPatientGroups.pending.type]: (state) => {
            state.patientGroupsLoading = true
        },
        [getPatientGroups.fulfilled.type]: (state, action: PayloadAction<Group[]>) => {
            state.groups = action.payload
            state.patientGroupsLoading = false
        },
        [getPatientGroups.rejected.type]: (state) => {
            state.patientGroupsLoading = false
        },
        [getPatientsInGroup.pending.type]: (state) => {
            state.patientsLoading = true
        },
        [getPatientsInGroup.fulfilled.type]: (state, action: PayloadAction<PatientSimple[]>) => {
            state.patientsSimple = action.payload
            state.patientsLoading = false
        },
        [getPatientsInGroup.rejected.type]: (state) => {
            state.patientsLoading = false
        },
        [setPatientInfo.pending.type]: (state) => {
            state.patientLoading = true
        },
        [setPatientInfo.fulfilled.type]: (state, action: PayloadAction<Patient>) => {
            state.selectedPatient = action.payload
            state.patientLoading = false
        },
        [setPatientInfo.rejected.type]: (state) => {
            state.patientLoading = false
        },
        [getNotifications.pending.type]: (state) => {
            state.notificationsLoading = true
        },
        [getNotifications.fulfilled.type]: (state, action: PayloadAction<Notification[]>) => {
            state.notifications = action.payload
            state.notificationsLoading = false
        },
        [getNotifications.rejected.type]: (state) => {
            state.notificationsLoading = false
        }
    }
})

export const { selectInstitution, clearInstitution, clearPatient, clearPatientData } = patientDataSlice.actions

// Selectors for accessing that part of the state using useSelector within components
export const selectPatientsSimple = (state: RootState) => state.patientData.patientsSimple

export default patientDataSlice.reducer