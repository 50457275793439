import * as errorMessage from "./ErrorMessage"

export function isLoginFormDataValid(data: FormData) {
    const username = String(data.get('username')).toLowerCase()
    const password = String(data.get('password'))

    if (!username) {
        return errorMessage.emptyField("Username");
    }
    if (!password) {
        return errorMessage.emptyField("Passwaord");
    }
    return true;
}

export function isRegisterFormDataValid(data: FormData, ins_id: any | null) {
    const username = String(data.get('username'))
    const password = String(data.get('password'))
    const email = String(data.get('email')).toLowerCase()
    const first_name = String(data.get('first_name'))
    const last_name = String(data.get('last_name'))

    if (!username) {
        return errorMessage.emptyField("Username");
    }
    if (!password) {
        return errorMessage.emptyField("Password");
    }
    if (!email) {
        return errorMessage.emptyField("Email");
    } else if (!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        return errorMessage.inValidFormat("Email");
    }
    if (!first_name) {
        return errorMessage.emptyField("First Name");
    }
    if (!last_name) {
        return errorMessage.emptyField("Last Name");
    }
    if (ins_id === null) {
        return errorMessage.notSelected("Institution");
    }
    return true;
}
