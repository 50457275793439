import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getAdminPatients, getDieticians, getPatientGroupsAdmin, updateAdminPage } from '../apiCalls'
import { RootState } from '../store'

export interface Dietician {
    last_name: string
    first_name: string
    email: string
    id: string
    approved: boolean
}

export interface PatientName {
    id: string
    first_name: string
    last_name: string
    email: string
    birth_date: Date
    gender: string
}

export interface PatientGroup {
    id: string
    name: string
}

interface AdminPageState {
    patients: PatientName[]
    dieticians: Dietician[]
    patientGroups: PatientGroup[]
    isLoading: boolean
}

const initialState: AdminPageState = {
    patients: [],
    dieticians: [],
    patientGroups: [],
    isLoading: false
}

export const adminPageSlice = createSlice({
    name: 'adminPage',
    initialState,
    reducers: {
        clear: (state) => {
            state.patients = []
            state.dieticians = []
            state.patientGroups = []
            state.isLoading = false
        }
    },
    extraReducers: {
        [getDieticians.pending.type]: (state) => {
            state.isLoading = true
        },
        [getDieticians.fulfilled.type]: (state, action: PayloadAction<Dietician[]>) => {
            state.dieticians = action.payload
            state.isLoading = false
        },
        [getDieticians.rejected.type]: (state) => {
            state.isLoading = false
        },
        [getAdminPatients.pending.type]: (state) => {
            state.isLoading = true
        },
        [getAdminPatients.fulfilled.type]: (state, action: PayloadAction<PatientName[]>) => {
            state.patients = action.payload
            state.isLoading = false
        },
        [getAdminPatients.rejected.type]: (state) => {
            state.isLoading = false
        },
        [getPatientGroupsAdmin.pending.type]: (state) => {
            state.isLoading = true
        },
        [getPatientGroupsAdmin.fulfilled.type]: (state, action: PayloadAction<PatientGroup[]>) => {
            state.patientGroups = action.payload
            state.isLoading = false
        },
        [getPatientGroupsAdmin.rejected.type]: (state) => {
            state.isLoading = false
        },
        [updateAdminPage.pending.type]: (state) => {
            state.isLoading = true
        },
        [updateAdminPage.fulfilled.type]: (state, action: PayloadAction<any>) => {

            state.dieticians = action.payload.dieticianList
            state.patientGroups = action.payload.groupList
            state.patients = action.payload.patientList

            state.isLoading = false
        },
        [updateAdminPage.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export const { clear } = adminPageSlice.actions

// Selectors for accessing that part of the state using useSelector within components
export const selectPatients = (state: RootState) => state.adminPage.patients
export const selectDieticians = (state: RootState) => state.adminPage.dieticians
export const selectPatientGroups = (state: RootState) => state.adminPage.patientGroups
export const isAdminLoading = (state: RootState) => state.adminPage.isLoading

export default adminPageSlice.reducer