import axios from "axios"

import { createAsyncThunk } from "@reduxjs/toolkit"

import { setError, clearError } from './slices/errorSlice'
import { RootState } from "./store"

import { InsInfoType } from "./slices/institutionSlice"
import { Dietician } from "./slices/adminPageSlice"
import { Patient } from "./slices/patientDataSlice"
import { StructuredType, UnionType } from "typescript"

const baseURL = 'https://phd.sciencella.care/'
//
//const baseURL = 'http://localhost:5000/'

export const axiosInstance = axios.create({
    baseURL,
    headers: {
    }
})


export const setUpInterceptors = (store: any) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const state = store.getState()
            const access_token = state.auth.authToken
            config.headers = {
                'Authorization': `Bearer ${access_token}`,
                'Accept': 'application/json'
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )

    axiosInstance.interceptors.response.use(
        (response) => {
            return response
        },
        async (error) => {
            const originalRequest = error.config
            if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
                originalRequest._retry = true
                const access_token = store.getState().auth.authToken
                const refresh_token = store.getState().auth.refreshToken
                await store.dispatch(refreshAccessToken(refresh_token))
                axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
                return axiosInstance(originalRequest)
            } else {
                if (error.response) {
                    store.dispatch(setError(JSON.stringify(error.response.data.message)))
                } else if (error.request) {
                    store.dispatch(setError('No response recieved from server'))
                } else {
                    store.dispatch(setError('Error making http request'))
                }

                return Promise.reject(error)
            }
        }
    )
}

//////////////////////////////////////////////////////// AUTH CALLS //////////////////////////////////////////////////////////////////////

/**
 * It make an API call to the back end to refresh the current user authentication token by request new authentication token
 * 
 * @param {string} refresh_token - The current refresh token  
 * @return {string | null} authToken - new authentication token 
 */
export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (refresh_token: string, { dispatch }) => {
        return await axios.post(baseURL.concat('user/token/refresh'), {}, {
            headers: {
                'Authorization': `Bearer ${refresh_token}`,
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                dispatch(clearError())

                const authToken: string| null = response.data.access_token
                return authToken
            })
    }
)

//LOGIN CALL
interface LoginInputObject {
    username: string,
    password: string,
    remember: FormDataEntryValue | null
}

/***
 * Make an API call to the back end to log in with the information provided
 * 
 * @param {LoginInputObject} loginCredentials - the data interface that hold the information 
 * @return { {authToken: string, refreshToken: string, remember: FormDataEntryValue | null } } 
 * authInfo - data holder that holds: {  
 *  -authToken - new authentication token  
 *  -refreshToken - new refresh token  
 *  -remember - same value as input's remember  
 * }
 */
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (loginCredentials: LoginInputObject, { dispatch }) => {
        return await axiosInstance.post('user/login', loginCredentials)
            .then((response) => {
                dispatch(clearError())
                
                /**
                 * Could make this another interface such as loginUserAuthInfo but we ran out of time :)
                 * @type { {authToken: string, refreshToken: string, remember: FormDataEntryValue | null } }
                 */
                const authInfo: { authToken: string; refreshToken: string; remember: FormDataEntryValue | null }  = {
                    authToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                    remember: loginCredentials.remember
                }

                return authInfo
            })
    }

)


//REGISTER CALL
interface RegisterInputObject {
    username: string,
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    institutions: InsInfoType[]
}

/**
 * Make an API call to the back end to register a user with information provided. 
 * Register the user to the institution will performe after register the current user  
 * 
 * @param {RegisterInputObject} registerInformation - the data interface that hold the information
 * @returns {{authToken: string, refreshToken: string}} authInfo - data holder that holds
 *  {  
 *      - authToken - the authentication token   
 *      - refreshToken - the refresh token 
 *  }
 * @returns {InsInfoType[]} ins - the Institutions user successfully enroll
 */
export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (registerInformation: RegisterInputObject, { dispatch, rejectWithValue }) => {
        return await axiosInstance.post('user/registration',
            {
                username: registerInformation.username,
                email: registerInformation.email,
                password: registerInformation.password,
                first_name: registerInformation.first_name,
                last_name: registerInformation.last_name
            })
            .then((response) => {
                dispatch(clearError())

                const authInfo :{authToken: string, refreshToken: string}= {
                    authToken: response.data.access_token,
                    refreshToken: response.data.refresh_token
                }

                return authInfo
            })
            .then(async (authInfo) => {

                const requests = registerInformation.institutions.map((ins) => {
                    return (
                        axios({
                            method: 'POST',
                            url: `${baseURL}dietitian/institution/register`,
                            headers: {
                                'Authorization': `Bearer ${authInfo.authToken}`,
                                'Accept': 'application/json'
                            },
                            data: {
                                ins_id: ins.id
                            }
                        })
                    )
                })

                return await axios.all(requests)
                    .then(() => {
                        return {
                            authInfo,
                            ins: registerInformation.institutions
                        }
                    })
            })
    }
)


//LOGOUT
/**
 * Make API call to the backend to log out current user.
 * 
 * @param None
 * @returns None
 */
export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async () => {
        await axiosInstance.post('user/logout/access')
    }
)

//REQUEST PASSWORD RESET CALL
/**
 * Make API call to the backend to reset the password of current user through email.
 * 
 * @param {String} email
 * @retuens None
 */
export const resetPasswordEmail = createAsyncThunk(
    'auth/resetPasswordEmail',
    async (email: String, { dispatch, rejectWithValue }) => {
        return await axiosInstance.post('/user/reset', { email })
            .then(() => {
                dispatch(clearError())
            })
    }
)

//RESET PASSWORD CALL
interface ResetObject {
    authToken: string | null,
    newPassword: string
}
/**
 * 
 * Make API call to the backend to reset the password with current user.
 * 
 * @param {ResetObject} resetInfo - the data interface that hold the authentication token and new password
 * @returns {string} response - the response info from backend
 */

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (resetInfo: ResetObject, { dispatch, rejectWithValue }) => {
        return await axiosInstance.post('/word?number=10', { new_password: resetInfo.newPassword }, { headers: { 'Authorization': 'Bearer ' + resetInfo.authToken } })
            .then((response) => {
                return JSON.stringify(response)
            })
    }
)
//GET INSTITUTIONS
interface InstitutionCallType {
    ['admin id']: string
    ['institution id']: string
    ['institution name']: string
}

/**
 * Get all Institution in the database
 * 
 * @param None
 * @returns { Array<InsInfoType>} ins - an Array of institutions
 */
export const getInstitutions = createAsyncThunk(
    'institutions/get',
    async (_, { dispatch }) => {
        return await axiosInstance.get('institution')
            .then((response) => {
                dispatch(clearError())

                const ins: Array<InsInfoType> = []

                response.data.institution.forEach((element: InstitutionCallType) => {
                    ins.push({
                        id: element['institution id'],
                        name: element['institution name']
                    })
                })

                return ins
            })
    }
)

//ADMIN LOGIN
interface AdminLogin {
    username: string
    password: string
}

/**
 * Make an API call to beackend to login a admin user.
 * 
 * @param {AdminLogin} loginInfo - the data interface that hold the login in admin user.
 * @returns {{authToken: string, refreshToken: string, ins_id: string, ins_name: string} } authInfo
 * authToken - the authentication token for current admin user  
 *     refreshToken - the refresh Token for current admin user  
 *     ins_id - the hashed id for teh institution admin user belong to  
 *     ins_name - the name of the institution   
 */
export const adminLogin = createAsyncThunk(
    'auth/adminLogin',
    async (loginInfo: AdminLogin, { dispatch }) => {
        return await axiosInstance.post('admin/login', loginInfo)
            .then((response) => {
                dispatch(clearError())

                const authInfo :{authToken: string, refreshToken: string, ins_id: string, ins_name: string} = {
                    authToken: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                    ins_id: response.data['institution id'],
                    ins_name: response.data['institution name']
                }

                return authInfo
            })
    }
)

//////////////////////////////////////////////////////// ADMIN PAGE CALLS //////////////////////////////////////////////////////////////////////

//GET DIETICIANS WITHIN INSTITUTION

/**
 * make an API call to GET DIETICIANS WITHIN INSTITUTION
 * Refer to the backend documentation
 * 
 * @param None
 * @return {Dietician[]}} an array of Dietitian
 */
export const getDieticians = createAsyncThunk(
    'adminPage/getDieticians',
    async () => {
        return await axiosInstance.get('admin/dietitianlist')
            .then((response) => {
                return response.data 
            })
            .then(async (dieticians) => {
                const dieticiansRequests = dieticians.map((d: any) => {
                    return (
                        axiosInstance({
                            method: 'GET',
                            url: `${baseURL}admin/retrievedietitian`,
                            params: {
                                dietitian_id: d.dietitian_id
                            }
                        })
                    )
                })

                return await axios.all(dieticiansRequests)
                    .then((dieticianInfo) => {
                        const finalDieticianArray: Dietician[] = dieticianInfo.map((dInfo: any) => {

                            const approved = dieticians.find((e: any) => {
                                return e.dietitian_id === dInfo.data.dietitian_id
                            }).access

                            return ({
                                last_name: dInfo.data.last_name,
                                first_name: dInfo.data['first name'],
                                email: dInfo.data.email,
                                id: dInfo.data.dietitian_id,
                                approved
                            })
                        })

                        return finalDieticianArray
                    })
            })
    }
)

//APPROVE DIETICIAN
/**
 * make API call to apporve an dietian to have access to the institution
 * 
 * @param {string} dietitian_id - the hashed dietitan id
 * @returns none
 */
export const approveDietician = createAsyncThunk(
    'adminPage/approveDietician',
    async (dietitian_id: string, { dispatch }) => {
        return await axiosInstance.put('admin/dietitianlist', { dietitian_id })
            .then(() => {
                dispatch(clearError())
            })
    }
)

//DENY DIETICIAN
/**
 * make API call to deny an dietian to have access to the institution
 * 
 * @param {string} dietitian_id - the hashed dietitan id
 * @returns none
 */
export const denyDietician = createAsyncThunk(
    'adminPage/denyDietician',
    async (dietitian_id: string, { dispatch }) => {
        return await axiosInstance.delete('admin/dietitianlist', {
            data: { dietitian_id }
        })
            .then(() => {
                dispatch(clearError())
            })
    }
)

//GET ADMIN PATIENTS ****** BACKEND SHOULD CHANGE WHAT INFO ENDPOINT RETURNS
export const getAdminPatients = createAsyncThunk(
    'adminPage/getPatients',
    async () => {
        return await axiosInstance.get('admin/patientlist')
            .then((response) => {
                return response.data
            })
            .then((patientList) => {
                return patientList.map((P: any) => {
                    return (
                        {
                            id: P.uuid,
                            first_name: P.first_name,
                            last_name: P.last_name,
                            email: P.email,
                            birth_date: P.birth_date,
                            gender: P.gender
                        }
                    )
                })
            })
    }
)

//GET PATIENT GROUPS 
export const getPatientGroupsAdmin = createAsyncThunk(
    'adminPage/getPatientGroups',
    async (_, { dispatch }) => {
        return await axiosInstance.get('admin/patientgrouplist')
            .then((response) => {
                dispatch(clearError())
                return response.data
            })
    }
)

//ADD PATIENT GROUP
export const addPatientGroup = createAsyncThunk(
    'adminPage/addPatientGroup',
    async (group_name: string, { dispatch }) => {
        return await axiosInstance.post('patient_group/register', { group_name })
            .then(() => {
                dispatch(clearError())
            })
    }
)

//DELETE PATIENT GROUP
export const deletePatientGroup = createAsyncThunk(
    'adminPage/deletePatientGroup',
    async (group_id: string, { dispatch }) => {
        return await axiosInstance.delete('patient_group/register', {
            data: {
                group_id
            }
        })
            .then(() => {
                dispatch(clearError())
            })
    }
)

//UPDATE ADMIN PAGE COMBINED CALL
export const updateAdminPage = createAsyncThunk(
    'adminPage/updateAdminPage',
    async () => {
        const dieticianList = await axiosInstance.get('admin/dietitianlist')
            .then((response) => {
                return response.data
            })
            .then(async (dieticians) => {
                const dieticiansRequests = dieticians.map((d: any) => {
                    return axiosInstance.get('admin/retrievedietitian', { params: { dietitian_id: d.dietitian_id } })
                })

                return await Promise.all(dieticiansRequests)
                    .then((dieticianInfo) => {
                        const finalDieticianArray: Dietician[] = dieticianInfo.map((dInfo) => {

                            const approved = dieticians.find((e: any) => {
                                return e.dietitian_id === dInfo.data.dietitian_id
                            }).access

                            return ({
                                last_name: dInfo.data.last_name,
                                first_name: dInfo.data['first name'],
                                email: dInfo.data.email,
                                id: dInfo.data.dietitian_id,
                                approved
                            })
                        })

                        return finalDieticianArray
                    })
            })


        const groupList = await axiosInstance.get('admin/patientgrouplist')
            .then((response) => {
                return response.data.map((g: any) => {
                    return ({
                        id: g.group_id,
                        name: g.group_name
                    })
                })
            })


        const patientList = await axiosInstance.get('admin/patientlist')
            .then((response) => {
                return response.data
            })
            .then((patientList) => {
                return patientList.map((P: any) => {
                    return (
                        {
                            id: P.uuid,
                            first_name: P.first_name,
                            last_name: P.last_name,
                            email: P.email,
                            birth_date: P.birth_date,
                            gender: P.gender
                        }
                    )
                })
            })

        return {
            dieticianList,
            groupList,
            patientList
        }
    }
)

//TEMP ADMIN ACCESS
export const grantTempAccess = async (dietitian_id: string) => {
    return await axiosInstance.post('admin/sendauthorization', { dietitian_id })
}

//////////////////////////////////////////////////////// DIETICIAN ACTIONS //////////////////////////////////////////////////////////////////////

//GET THE INSTITUTIONS A DIETICIAN IS A PART OF
export const getInstitutionsDietician = createAsyncThunk(
    'patientData/getInstitutionsDietician',
    async () => {
        return await axiosInstance.get('dietitian/institution/register')
            .then(async (institutions_res) => {
                const ins_info = institutions_res.data['All institutions']

                const requests = ins_info.map((ins: any) => {
                    return axiosInstance({
                        method: 'GET',
                        url: 'institution/register',
                        params: {
                            ins_id: ins.ins_id
                        }
                    })
                })

                const ins_names_responses = await Promise.all(requests)
                const ins_names = ins_names_responses.map((x: any) => {
                    return {
                        ins_id: x.data['institution id'],
                        name: x.data['institution name']
                    }
                })

                const mappedInstitutionInfo = ins_info.map((ins: any) => {
                    const name = ins_names.find((x: any) => x.ins_id === ins.ins_id)?.name
                    return {
                        id: ins.ins_id,
                        name,
                        access: ins.access
                    }
                })

                return mappedInstitutionInfo
            })
    }
)

//GET ALL PATIENTS DIETICIAN CAN SEE
export const getAllPatients = createAsyncThunk(
    'patientData/getAllPatients',
    async () => {
        const patient_ids = await axiosInstance.get('user/patients')
            .then((patientsRes) => {
                return patientsRes.data.patients.map((x: any) => {
                    return x.patient_id
                })
            })

        return await axiosInstance.post('patientuser/batch', {
            patients: patient_ids.map((patient_id: string) => {
                return { patient_id }
            }),
            search_term: ['first_name', 'last_name', 'email', 'uuid', 'ins_id', 'gender', 'height', 'birth_date']
        })
            .then((res) => {
                return res.data.patients.map((P: any) => {
                    return {
                        id: P.uuid,
                        email: P.email,
                        first_name: P.first_name,
                        last_name: P.last_name,
                        ins_id: P.ins_id,
                        gender: P.gender,
                        height: P.height,
                        birth_date: P.birth_date
                    }
                })
            })
    }
)

//GET ALL PATIENT GROUPS FOR AN INSTITUTION
export const getPatientGroups = createAsyncThunk(
    'patientData/getPatientGroups',
    async (ins_id: string) => {
        const groupIds = await axiosInstance.get('institution/groups', { params: { ins_id } })
            .then(res => {
                return res.data.groups.map((x: any) => {
                    return x.group_id
                })
            })

        const groupsAPartOf = await axiosInstance.get('dietitian_patient_group')
            .then(res => {
                return res.data.group.map((x: any) => {
                    return x.group_id
                })
            })

        const groupNameRequests = groupIds.map((G: string) => {
            return axiosInstance({
                method: 'GET',
                url: 'patient_group/retrieve',
                params: {
                    group_id: G
                }
            })
        })

        const groupNames = await Promise.all(groupNameRequests)
            .then(responses => {
                return responses.map((res: any) => {
                    return {
                        id: res.data.group_id,
                        name: res.data.group_name
                    }
                })
            })

        return groupIds.map((G: any, index: number) => {
            const subscribed = groupsAPartOf.find((x: any) => x === G) ? true : false
            const name = groupNames.find((x: any) => x.id === G)?.name

            return {
                id: G,
                name,
                subscribed,
                loading: false
            }
        })
    }
)

//GET ALL PATIENTS IN PATIENT GROUP
export const getPatientsInGroup = createAsyncThunk(
    'patientData/getPatientsInGroup',
    async (group_id: string) => {
        const patientIds = await axiosInstance.get('patient_group/register', { params: { group_id } })
            .then(res => {
                return res.data['Patient List'].map((X: any) => {
                    return X.patient_id
                })
            })

        return await axiosInstance.post('patientuser/batch', {
            patients: patientIds.map((patient_id: string) => {
                return { patient_id }
            }),
            search_term: ['first_name', 'last_name', 'email', 'uuid', 'ins_id', 'gender', 'height', 'birth_date']
        })
            .then((res) => {
                return res.data.patients.map((P: any) => {
                    return {
                        id: P.uuid,
                        email: P.email,
                        first_name: P.first_name,
                        last_name: P.last_name,
                        ins_id: P.ins_id,
                        gender: P.gender,
                        height: P.height,
                        birth_date: P.birth_date
                    }
                })
            })
    }
)

//SUBSCRIBE DIETITIAN TO PATIENT GROUP
export const subscribeToPatientGroup = createAsyncThunk(
    'patientData/subscribeToPatientGroup',
    async (group_id: string, { dispatch, getState }) => {
        await axiosInstance.post('dietitian_patient_group/register', { group_id })
        const state = getState() as RootState
        if (state) {
            if (state.patientData.selectedInstitution) {
                dispatch(getPatientGroups(state.patientData.selectedInstitution.id))
            }
        }
    }
)

//UNSUBSCRIBE FROM PATIENT GROUP
export const unsubscribeFromPatientGroup = createAsyncThunk(
    'patientData/unsubscribeFromPatientGroup',
    async (group_id: string, { dispatch, getState }) => {
        await axiosInstance.delete('dietitian_patient_group/register', { params: { group_id } })
        const state = getState() as RootState
        if (state) {
            if (state.patientData.selectedInstitution) {
                dispatch(getPatientGroups(state.patientData.selectedInstitution.id))
            }
        }
    }
)

/////////////////////////////////////////////////// SPECIFIC PATIENT STUFF //////////////////////////////////////////////////////////

//GET SPECIFIC INFO ON ONE PATIENT
export const getPatientInfo = createAsyncThunk(
    'patientData/getSpecificPatientInfo',
    async (user_id: string) => {
        const patient = await axiosInstance.get('patientuser/user_id', { params: { user_id } })
            .then((res) => {
                return res.data
            })

        return patient
    }
)

//UPDATE SPECIFIC PATIENT INFO
export const setPatientInfo = createAsyncThunk(
    'patientData/setPatientInfo',
    async (updatedPatient: Patient) => {
        return await axiosInstance.put('patientuser', {
            user_id: updatedPatient.uuid,
            patient: {
                min_energy: updatedPatient.min_energy,
                max_energy: updatedPatient.max_energy,
                min_protein: updatedPatient.min_protein,
                max_protein: updatedPatient.max_protein,
                min_total_lipid: updatedPatient.min_total_lipid,
                max_total_lipid: updatedPatient.max_total_lipid,
                min_carbohydrate: updatedPatient.min_carbohydrate,
                max_carbohydrate: updatedPatient.max_carbohydrate,
                min_fiber: updatedPatient.min_total_lipid,
                max_fiber: updatedPatient.max_fiber,
                min_sugar: updatedPatient.min_sugar,
                max_sugar: updatedPatient.max_sugar,
                min_calcium: updatedPatient.min_calcium,
                max_calcium: updatedPatient.max_calcium,
                min_iron: updatedPatient.min_iron,
                max_iron: updatedPatient.max_iron,
                min_sodium: updatedPatient.min_sodium,
                max_sodium: updatedPatient.max_sodium,
                min_vitamin_a: updatedPatient.min_vitamin_a,
                max_vitamin_a: updatedPatient.max_vitamin_a,
                min_vitamin_c: updatedPatient.min_vitamin_c,
                max_vitamin_c: updatedPatient.max_vitamin_c,
                min_vitamin_d: updatedPatient.min_vitamin_d,
                max_vitamin_d: updatedPatient.max_vitamin_d,
                min_saturated_fatty_acid: updatedPatient.min_saturated_fatty_acid,
                max_saturated_fatty_acid: updatedPatient.max_saturated_fatty_acid,
                min_monounsaturated_fatty_acid: updatedPatient.min_monounsaturated_fatty_acid,
                max_monounsaturated_fatty_acid: updatedPatient.max_monounsaturated_fatty_acid,
                min_polyunsaturated_fatty_acid: updatedPatient.min_polyunsaturated_fatty_acid,
                max_polyunsaturated_fatty_acid: updatedPatient.max_polyunsaturated_fatty_acid,
                min_cholesterol: updatedPatient.min_cholesterol,
                max_cholesterol: updatedPatient.max_cholesterol
            }
        })
            .then(res => {
                return res.data
            })
    }
)

//ADD PATIENT TO PATIENT GROUP
export interface PatientGroupInput {
    patient_id: string,
    group_id: string
}

export const addPatientToGroup = createAsyncThunk(
    'patientData/addPatientToGroup',
    async (input: PatientGroupInput) => {
        await axiosInstance.post('patient_patient_group/register', input)
    }
)

//REMOVE PATIENT FROM PATIENT GROUP
export const deletePatientFromGroup = createAsyncThunk(
    'patientData/deletePatientFromGroup',
    async (input: PatientGroupInput) => {
        await axiosInstance.delete('patient_patient_group/register', {
            params: {
                patient_id: input.patient_id,
                group_id: input.group_id
            }
        })
    }
)


////////////////////////////////////////////// NOTIFICATION CALLS ///////////////////////////////////////////////////

//GET DIETICIAN'S NOTIFICATIONS
export const getNotifications = createAsyncThunk(
    'patientData/getNotifications',
    async () => {
        return await axiosInstance.get('notification/list')
            .then(res => {
                return res.data.dietitian_notifications
            })
    }
)

//SEND NOTIFICATION
export interface SentNotification {
    patient_id: string,
    notification_type: string,
    text: string
}

export const sendNotification = createAsyncThunk(
    'patientData/sendNotification',
    async (notif: SentNotification, { dispatch }) => {
        await axiosInstance.post('notification', notif)
        dispatch(getNotifications())
    }
)

//DELETE NOTIFICATION
export const deleteNotification = createAsyncThunk(
    'patientData/deleteNotification',
    async (notification_id: string, { dispatch }) => {
        await axiosInstance.delete('notification', {
            data: {
                notification_id
            }
        })
        dispatch(getNotifications())
    }
)