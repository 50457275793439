import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ErrorState {
    currentError: string
}

const initialState: ErrorState = {
    currentError: ''
}

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string>) => {
            state.currentError = action.payload
        },
        clearError: (state) => {
            state.currentError = ''
        }
    }
})

export const { setError, clearError } = errorSlice.actions

// Selectors for accessing that part of the state using useSelector within components
export const selectError = (state: RootState) => state.error.currentError

export default errorSlice.reducer