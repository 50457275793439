import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux"

import { RootState } from './store';

import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import SignUpPage from './components/Register'
import ResetPage from './components/Reset';
import AdminPage from './components/AdminPage';
import AdminLoginPage from './components/AdminLogin';

import Snackbar from '@mui/material/Snackbar';

import { clearError } from './slices/errorSlice'
import { setUpInterceptors } from './apiCalls'
import Alert from '@mui/material/Alert';

function App() {
  const store = useStore()
  setUpInterceptors(store)

  const dispatch = useDispatch()

  const isAuthLoading = useSelector((state: RootState) => state.auth.isLoading)
  const authToken = useSelector((state: RootState) => state.auth.authToken)
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin)
  const error = useSelector((state: RootState) => state.error.currentError)

  const closeErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(clearError())
  }

  return (
    <div>
      <Snackbar
        open={error ? true : false}
        onClose={closeErrorSnackbar}
      >
        <Alert
          severity='error'
          sx={{ width: '100%' }}
          onClose={closeErrorSnackbar}
        >
          {error}
        </Alert>
      </Snackbar>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={authToken ? <Navigate to='/dashboard' /> : <Navigate to='/login' />} />
          <Route path='/login' element={authToken ? <Navigate to='/dashboard' /> : <LoginPage />} />
          <Route path='/register' element={(authToken && !isAuthLoading) ? <Navigate to='/dashboard' /> : <SignUpPage />} />
          <Route path='/reset' element={authToken ? <Dashboard /> : <ResetPage />} />
          <Route path='/dashboard' element={(authToken && !isAdmin) ? <Dashboard /> : <Navigate to='/login' />} />
          <Route path='/adminLogin' element={(authToken && isAdmin) ? <AdminPage /> : <AdminLoginPage />} />
          <Route path='/admin' element={(authToken && isAdmin) ? <AdminPage /> : <Navigate to='/adminLogin' />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
